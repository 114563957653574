import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import PageNotFound from "@pages/utilities/PageNotFound";
import Home from '@pages/Home';


export default function App() {
    return (
        <Router>
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </Router>
    )
}
