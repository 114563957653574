import { alert, times } from '@assets/icons';


export default function Alert({ isOpen, close }) {

    return isOpen ? (
        <>
            <div className="bg-opacity-50 h-screen w-screen bg-black fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 grid place-items-center">
                <div className="w-72 h-72 md:w-80 rounded-md bg-base-100 shadow-md p-1">
                    <div className="ml-auto w-fit cursor-pointer" onClick={close}>
                        {times}
                    </div>

                    <div className="text-center flex flex-col justify-center px-1 w-full">
                        <div className="mx-auto">{alert}</div>

                        <h3 className="text-[20px] font-prata mt-4">Message Sent Successfully!</h3>
                        <p>Thank you for reaching out to us.<br/> We'll get back to you as soon as possible</p>
                    </div>
                </div>
            </div>
        </>
    ) : (
        <></>
    )
}
