const scrollTo = to => {
    const element = document.querySelector(to);
    element && element.scrollIntoView();
}


export function PrimaryButton({ children, className, onClick = () => { }, to = null, type = "button" }) {

    return (
        <>
            <button onClick={() => { scrollTo(to); onClick() }} type={type} className={`text-center h-fit border md:border-2 text-base-100 border-primary-500 hover:border-primary-600 bg-primary-500 hover:bg-primary-600 hover:shadow min-w-[136px] md:px-8 py-2 md:py-4 font-semibold text-xs md:text-base inline-flex items-center justify-center ${className}`}>
                {children}
            </button>
        </>
    )
}

export function SecondaryButton({ children, className, onClick = () => { }, to = "#" }) {

    return (
        <>
            <a href={to}>
                <button onClick={() => { scrollTo(to); onClick() }} type="button" className={`border md:border-2 border-base-100 hover:bg-base-100 hover:text-base-800 transition-all text-base-100 hover:shadow min-w-[136px] md:px-8 py-2 md:py-4 font-semibold text-xs md:text-base ${className}`}>
                    {children}
                </button>
            </a>
        </>
    )
}

