import { useEffect } from "react";
import { Link } from "react-router-dom";

import { logo } from '@assets/icons';


export default function PageNotFound({ text = 'page not found' }) {

    useEffect(() => {
        document.title = '404 | Topaz Imperial Logistics'

        return () => {
            document.title = 'Home | Topaz Imperial Logistics'
        }

    }, [])

    return (
        <>
            <div className="w-full h-screen">
                <header className="bg-base-100 grid place-items-center py-4 px-4 dark:bg-base-800">
                    <Link to="/">
                        {logo}
                    </Link>
                </header>

                <div className="w-full h-5/6 flex justify-center items-center flex-col relative text-center px-4">
                    <div>
                        <p className="text-[150px] text-white font-semibold leading-none">404</p>
                        <p className="mt-6 text-white text-center">
                            Page not found.
                            Click <Link className="underline text-base" to="/">here</Link> to go to back home
                        </p>
                    </div>
                </div>

            </div>
        </>
    )
}
