import axios from 'axios';

export async function sendEmail (formRef) {
    try {
      // Accessing form fields using useRef
      const name = formRef.current.name.value;
      const email = formRef.current.email.value;
      const message = formRef.current.message.value;
  
      // Creating request data object
      const requestData = {
        name: name,
        from_: email,
        message: message,
        // to: "topaz_imperial34@yahoo.com",
        to: "theorchestra03@gmail.com",
        platform: window.location.hostname 
      };
  
      // Sending POST request using Axios
      await axios.post('https://utilities-api.vercel.app/email/send', requestData);
  
      // Handle the response as needed
    } catch (error) {
      console.log({error})
      // Handle errors
    }
  };