export const logo = (
    <svg width="48" height="44" viewBox="0 0 48 44" fill="none" xmlns="http://www.w3.org/2000/svg" className="child:fill-base-900 child-hover:fill-base-800 dark:child-hover:fill-base-200 dark:child:fill-base-100">
        <path d="M24.3138 0L14.2745 12.9594H33.8824L24.3138 0Z" fill="currentColor" />
        <path d="M19.451 2.48328L1.96082 12.8043H10.9804L19.451 2.48328Z" fill="currentColor" />
        <path d="M45.5686 12.8042H37.098L28.9412 2.40564L45.5686 12.8042Z" fill="currentColor" />
        <path d="M10.5882 15.2875H0L20.2353 39.6543L10.5882 15.2875Z" fill="currentColor" />
        <path d="M48 15.2098H37.4118L28.7059 38.9559L48 15.2098Z" fill="currentColor" />
        <path d="M34.5883 15.2875H13.4902L24.4706 44L34.5883 15.2875Z" fill="currentColor" />
    </svg>
)

export const logo_white = (
    <svg width="48" height="44" viewBox="0 0 48 44" fill="none" xmlns="http://www.w3.org/2000/svg" className="child:fill-base-100 child-hover:fill-base-100">
        <path d="M24.3137 0L14.2745 12.9594H33.8824L24.3137 0Z" fill="currentColor" />
        <path d="M19.451 2.48315L1.96079 12.8041H10.9804L19.451 2.48315Z" fill="currentColor" />
        <path d="M45.5686 12.8041H37.098L28.9412 2.40552L45.5686 12.8041Z" fill="currentColor" />
        <path d="M10.5882 15.2874H0L20.2353 39.6542L10.5882 15.2874Z" fill="currentColor" />
        <path d="M48 15.21H37.4117L28.7059 38.956L48 15.21Z" fill="currentColor" />
        <path d="M34.5883 15.2874H13.4902L24.4706 43.9999L34.5883 15.2874Z" fill="currentColor" />
    </svg>

)

export const clock = (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" className="stroke-grey-700 dark:stroke:grey-300">
        <path d="M32 3.33334C37.6698 3.33334 43.2122 5.01461 47.9264 8.16455C52.6406 11.3145 56.3149 15.7916 58.4846 21.0298C60.6543 26.2679 61.222 32.0318 60.1159 37.5926C59.0098 43.1534 56.2795 48.2613 52.2704 52.2704C48.2613 56.2795 43.1534 59.0097 37.5926 60.1159C32.0318 61.222 26.2679 60.6543 21.0298 58.4846C15.7916 56.3148 11.3145 52.6406 8.16458 47.9264C5.01464 43.2121 3.33337 37.6697 3.33337 32C3.33691 24.3982 6.35827 17.1088 11.7335 11.7335C17.1088 6.35824 24.3983 3.33688 32 3.33334Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M32 35.3333C33.841 35.3333 35.3334 33.8409 35.3334 32C35.3334 30.159 33.841 28.6667 32 28.6667C30.1591 28.6667 28.6667 30.159 28.6667 32C28.6667 33.8409 30.1591 35.3333 32 35.3333Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M32 28.6667V15.2533M34.8 33.8L51.0667 44.2" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

export const dollar = (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" className="stroke-grey-700 dark:stroke:grey-300">
        <g id="arcticons:currency-dollar">
            <path id="Vector" d="M24.6934 41.1694C26.1334 43.0414 27.936 43.74 30.4454 43.74H33.9187C34.6881 43.7391 35.4497 43.5867 36.1602 43.2915C36.8707 42.9963 37.516 42.564 38.0594 42.0193C38.6029 41.4747 39.0337 40.8283 39.3273 40.1172C39.6209 39.4061 39.7716 38.6441 39.7707 37.8747C39.7714 37.1053 39.6206 36.3433 39.3268 35.6323C39.033 34.9212 38.602 34.2749 38.0585 33.7304C37.515 33.1859 36.8695 32.7538 36.159 32.4587C35.4484 32.1636 34.6867 32.0114 33.9174 32.0107H30.0814C29.3119 32.01 28.5501 31.8577 27.8394 31.5626C27.1288 31.2674 26.4833 30.8351 25.9398 30.2905C25.3962 29.7458 24.9653 29.0994 24.6716 28.3882C24.3779 27.677 24.2272 26.9148 24.228 26.1453C24.2273 25.376 24.3782 24.614 24.672 23.9029C24.9657 23.1919 25.3967 22.5456 25.9402 22.0011C26.4838 21.4566 27.1292 21.0244 27.8398 20.7294C28.5503 20.4343 29.312 20.2821 30.0814 20.2814H33.5547C36.064 20.2814 37.868 20.9787 39.3054 22.8507M32 46.6613V17.3387" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
            <path id="Vector_2" d="M32 60.6666C47.8322 60.6666 60.6667 47.8321 60.6667 32C60.6667 16.1678 47.8322 3.33331 32 3.33331C16.1679 3.33331 3.33337 16.1678 3.33337 32C3.33337 47.8321 16.1679 60.6666 32 60.6666Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        </g>
    </svg>
)

export const support = (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" className="stroke-grey-700 dark:stroke:grey-300">
        <rect x="0.4" y="0.4" width="63.2" height="63.2" rx="31.6" stroke="currentColor" strokeWidth="0.8" />
        <path fillRule="evenodd" clipRule="evenodd" d="M22.7542 40.3997L25.5785 39.0426C28.8952 37.5222 30.8553 38.3969 33.6278 39.4781C36.8038 40.7188 38.005 41.1355 39.3228 41.663C41.6882 42.6127 40.5962 45.7493 38.2271 45.113C36.7872 44.7263 33.0226 43.6207 31.9713 43.3654C30.7868 43.0764 30.25 44.4954 31.3735 44.9009C33.4205 45.6385 36.3023 46.3837 37.8403 46.791C39.3783 47.2002 40.2371 46.5189 41.3217 45.6442C42.9986 44.2927 46.815 40.1839 46.815 40.1839C48.3734 38.6841 50.5 39.9305 50.5 41.5353C50.5 41.967 50.3464 42.425 49.9892 42.8624C45.6804 48.1049 44.3256 49.6985 41.5346 52.6718C40.3815 53.9012 39.0507 54.5 37.4553 54.5C36.6373 54.5 35.7489 54.3423 34.7846 54.0307C31.4068 52.9402 27.0629 51.5662 22.7542 51.0275V40.3997ZM20.9033 52.6718H13.5V37.6555H20.9033V52.6718ZM22.7542 37.9014V34.6842C22.7431 31.7616 22.8689 30.0911 26.1856 29.314C29.8188 28.4637 34.172 27.7016 32.4488 24.4768C27.3387 14.919 30.9922 9.5 36.4781 9.5C41.8585 9.5 45.6027 14.7182 40.5074 24.4768C38.8342 27.6828 43.0578 28.4449 46.7706 29.314C50.1002 30.0929 50.2113 31.7748 50.2002 34.7179C50.2002 34.962 50.1798 36.9159 50.2002 37.8432C49.3062 37.2632 47.5313 36.8071 46.1524 38.0422C45.1622 38.9281 45.1011 38.9882 42.8524 41.0999C39.6134 39.54 34.605 37.5654 31.3513 36.4486C29.6929 35.878 27.5756 35.8704 25.8691 36.5893L22.7542 37.9014Z" stroke="currentColor" strokeWidth="0.8" />
    </svg>
)

export const alert = (
    <svg width="108" height="109" viewBox="0 0 108 109" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_31_1278)">
            <path d="M54 6.90002C44.5065 6.90002 35.2262 9.71517 27.3326 14.9895C19.4391 20.2638 13.2868 27.7604 9.6538 36.5312C6.0208 45.3021 5.07024 54.9533 6.92233 64.2644C8.77442 73.5754 13.346 82.1282 20.0589 88.8412C26.7718 95.5541 35.3246 100.126 44.6357 101.978C53.9468 103.83 63.598 102.879 72.3688 99.2462C81.1397 95.6132 88.6363 89.461 93.9106 81.5674C99.1849 73.6738 102 64.3935 102 54.9C102 42.1696 96.9429 29.9606 87.9411 20.9589C78.9394 11.9572 66.7304 6.90002 54 6.90002ZM54 96.9C45.6932 96.9 37.5729 94.4368 30.6661 89.8217C23.7592 85.2067 18.376 78.6472 15.1971 70.9727C12.0182 63.2982 11.1865 54.8534 12.807 46.7062C14.4276 38.559 18.4277 31.0753 24.3015 25.2015C30.1753 19.3277 37.659 15.3276 45.8062 13.707C53.9534 12.0865 62.3982 12.9182 70.0727 16.0971C77.7472 19.276 84.3067 24.6592 88.9218 31.5661C93.5368 38.4729 96 46.5932 96 54.9C96 66.0391 91.575 76.722 83.6985 84.5985C75.822 92.475 65.1391 96.9 54 96.9Z" fill="#4AC440" />
            <path d="M84 37.2C83.4379 36.6413 82.6776 36.3276 81.885 36.3276C81.0924 36.3276 80.3321 36.6413 79.77 37.2L46.47 70.35L28.47 52.35C27.921 51.7573 27.159 51.4069 26.3517 51.3759C25.5443 51.345 24.7578 51.636 24.165 52.185C23.5722 52.734 23.2218 53.496 23.1909 54.3033C23.16 55.1107 23.451 55.8973 24 56.49L46.47 78.9L84 41.46C84.2812 41.1811 84.5044 40.8493 84.6567 40.4837C84.809 40.1182 84.8874 39.7261 84.8874 39.33C84.8874 38.934 84.809 38.5419 84.6567 38.1763C84.5044 37.8107 84.2812 37.4789 84 37.2Z" fill="#4AC440" />
        </g>
        <defs>
            <clipPath id="clip0_31_1278">
                <rect width="108" height="108" fill="white" transform="translate(0 0.900024)" />
            </clipPath>
        </defs>
    </svg>
)

export const times = (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M25.5 10.5L10.5 25.5M10.5 10.5L25.5 25.5" stroke="#1B1B1B" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)
