import { useRef, useState, useEffect } from 'react';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link } from 'react-router-dom';
import { gsap } from "gsap";

import { PrimaryButton, SecondaryButton } from '@common/components/Buttons';
import Alert from '@common/components/Alert';

import { clock, dollar, logo_white, support, logo } from '@assets/icons';
import { openHours, services1, services2 } from '@assets/images';
import { sendEmail } from '@shared/utils';


export default function Home() {
    gsap.registerPlugin(ScrollTrigger);

    const [isOpen, setIsOpen] = useState(false)

    const formRef = useRef(null)
    const nameRef = useRef(null)

    useEffect(() => {
        const duration = 1, delay = 0.1
        const from = { y: 100, opacity: 0 }

        // begin gsap hero animation
        document.querySelectorAll(".fade-in").forEach((element, i) => {
            gsap.from(element, from)
            gsap.to(element, { y: 0, duration: 2.5, delay: 0.3 * i, ease: "power4.out" });
            gsap.to(element, { opacity: 1, duration: 2.5, delay: 0.3 * i, ease: "power4.inOut" });
        });

        // setup body animations - fade-section
        document.querySelectorAll(".fade-section").forEach((element, i) => {

            // loop through child nodes
            element.childNodes.forEach((el, i) => {
                gsap.from(el, { ...from, scrollTrigger: el })
                gsap.to(el, { y: 0, duration, delay, ease: "power4.out", scrollTrigger: el });
                gsap.to(el, { opacity: 1, duration, delay, ease: "power4.inOut", scrollTrigger: el });
            });
        });

        // setup other animations
        document.querySelectorAll(".fade").forEach((element, i) => {
            gsap.from(element, { ...from, scrollTrigger: element })
            gsap.to(element, { y: 0, duration, delay, ease: "power4.out", scrollTrigger: element });
            gsap.to(element, { opacity: 1, duration, delay, ease: "power4.inOut", scrollTrigger: element });
        });

        // setup footer animations
        gsap.from("#contact-us", { opacity: 0, scrollTrigger: "#contact-us" })
        gsap.to("#contact-us", { opacity: 1, duration, delay, ease: "power4.out", scrollTrigger: "#contact-us" });

    }, [])

    const autoFocus = () => {
        setTimeout(() => nameRef.current.focus(), 0)
    }

    const handleSubmit = async e => {
        e.preventDefault();

        // send email
        sendEmail(formRef)

        // alert
        setIsOpen(true)

        // empty fields
        formRef.current.childNodes.forEach(element => {
            element.value = ''
        });
    }

    return (
        <>
            {/* header */}
            <header className="bg-base-100 grid place-items-center py-4 px-4 dark:bg-base-900">
                <Link to="/">
                    {logo}
                </Link>
            </header>

            <main>
                {/* hero */}
                <div className='px-4 md:px-8 lg:px-32 hero-image h-[560px] lg:h-[600px]'>
                    <div className='pt-52'>
                        <h1 className='fade-in h1 text-base-100 break-words md:break-normal'>
                            Anytime, Anywhere
                        </h1>
                        <p className='fade-in text-base-100 -mt-1'>
                            Topaz Imperial Logistics - Ensuring your deliveries, every mile, every time
                        </p>
                        <div className='fade-in mt-6 flex items-center gap-3 sm:gap-4'>
                            <PrimaryButton to="#contact-us" onClick={() => autoFocus()}>
                                Book A Shipment
                            </PrimaryButton>
                            <SecondaryButton to="#content">
                                Learn More...
                            </SecondaryButton>
                        </div>
                    </div>
                </div>
                <span id="content"></span>

                {/* section 2 */}
                <section id='section-2' className='text-center grid place-items-center fade-section'>
                    <h2 className='h2'>
                        Timely, Consistent, Reliable
                    </h2>
                    <p>
                        Trust us to navigate the intricate network of logistics, delivering a service marked by punctuality, steadfastness, and dependability
                    </p>

                    <div className='flex gap-10 md:gap-14 lg:gap-36 flex-col md:flex-wrap md:flex-row mt-14 mx-auto'>

                        <div className='flex flex-col gap-4 justify-center items-center fade'>
                            {clock}
                            <p>On Time Delivery</p>
                        </div>

                        <div className='flex flex-col gap-4 justify-center items-center fade'>
                            {dollar}
                            <p>Affordable Freight Transport</p>
                        </div>

                        <div className='flex flex-col gap-4 justify-center items-center fade'>
                            {support}
                            <p>Professional Service</p>
                        </div>

                    </div>
                </section>

                <section id="services" className="fade-section">
                    <h2 className='h2'>Our Services</h2>

                    <div className='mt-10 flex flex-col gap-10'>
                        <div className='flex gap-8 flex-col md:flex-row'>
                            <img alt="service" src={services1} className='rounded-sm object-cover h-80 w-full md:w-6/12 lg:w-7/12 fade' />

                            <div className="p-0 md:my-auto w-full md:w-6/12 lg:w-5/12 fade">
                                <h3 className="h3">
                                    We excel in efficient inland cargo transport.
                                </h3>
                                <div>
                                    <p className='mb-4'>
                                        Navigating the journey of transporting your goods from point A to point B can be a challenging task. At Topaz Imperial Logistics, we stand ready to assist.
                                    </p>
                                    <p>
                                        As a privately-owned logistics and transportation company, our sole commitment is to deliver top-notch services to you and our valued customer base.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className='flex gap-8 flex-col-reverse md:flex-row flex-1'>
                            <div className="p-0 md:my-auto w-full md:w-6/12 lg:w-5/12 fade">
                                <h3 className="h3">
                                    Need A Truck?
                                </h3>
                                <div className="mb-4">
                                    <p className='mb-4'>
                                        In addition to our transportation solutions, we offer comprehensive rental services tailored to meet your specific requirements.
                                    </p>
                                    <p>
                                        Whether you're looking for reliability, efficiency, or versatility, our diverse range of trucks is designed to exceed your expectations.
                                    </p>
                                </div>
                                <PrimaryButton to="#contact-us" onClick={() => autoFocus()}>
                                    Book A Rental
                                </PrimaryButton>
                            </div>

                            <img alt="service" src={services2} className='rounded-sm object-cover h-80 w-full md:w-6/12 lg:w-7/12 fade' />
                        </div>

                    </div>
                </section>

                <section id="open-hours" className='xl:w-9/12 2xl:w-8/12 fade'>
                    <div className='flex items-start md:items-center flex-col md:flex-row bg-base-200 dark:bg-neutral-900 rounded-tr-sm rounded-tl-sm'>
                        <div className='h-fit md:h-80 w-full px-4 py-8 flex md:items-center md:w-5/12'>
                            <div className="my-auto fade-section">
                                <h3 className="h3">
                                    Our Open Hours
                                </h3>
                                <div className="px-4 mt-2 flex items-start gap-10">
                                    <div>
                                        <p>Mon-Fri:</p>
                                        <p className='my-5'>Saturday:</p>
                                        <p>Sunday:</p>
                                    </div>
                                    <div>
                                        <p>8am - 6pm</p>
                                        <p className='my-5'>By Appointment</p>
                                        <p>Closed</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='h-80 w-full md:w-7/12'>
                            <img alt="service" src={openHours} className='rounded-br-sm rounded-bl-sm opacity-80 object-cover h-full w-full' />
                        </div>
                    </div>
                </section>

                <section id="about" className="text-center fade-section">
                    <h3 className="h3">
                        Topaz Imperial Logistics
                    </h3>
                    <p>
                        It's not just about doing the work, it's how we do it that matters. At Topaz Imperial Logistics, we put the safety of your products above all else.
                        We get the job done faster and better than anyone else. What more could you ask for?!
                    </p>
                </section>

                <footer id="contact-us" className="bg-base-800 px-4 pt-12 text-base-100 mt-14 pb-5 dark:bg-base-900 fade-section">
                    <div className="grid place-items-center mb-10 child:fill-base-100">
                        {logo_white}
                    </div>

                    <div className="flex flex-col items-start gap-9 md:flex-row-reverse md:justify-between md:max-w-xl lg:max-w-3xl mx-auto fade-section">
                        <div>
                            <h3 className='h3'>
                                Contact Us
                            </h3>
                            <ul className='list-disc pl-5'>
                                <li>
                                    <a href='phone:+16616759143'>+16616759143</a>
                                </li>
                            </ul>
                        </div>

                        <form ref={formRef} className="flex flex-col gap-4 w-72" action="" method="post" onSubmit={event => handleSubmit(event)}>
                            <input placeholder='name' type="text" name="name" required ref={nameRef}
                                className="px-4 py-2 placeholder:uppercase placeholder:text-neutral-400 dark:placeholder:text-neutral-400 bg-base-100 dark:bg-base-100 border rounded-sm border-base-100 text-base-800 dark:text-base-800 focus:bg-white focus:border-base-800 dark:focus:border-base-800" />

                            <input placeholder='email' type="email" name="email" required
                                className="px-4 py-2 placeholder:uppercase placeholder:text-neutral-400 dark:placeholder:text-neutral-400 bg-base-100 dark:bg-base-100 border rounded-sm border-base-100 text-base-800 dark:text-base-800 focus:bg-white focus:border-base-800 dark:focus:border-base-800" />

                            <textarea placeholder='message' name="message" required
                                className="min-h-[120px] px-4 py-2 placeholder:uppercase placeholder:text-neutral-400 dark:placeholder:text-neutral-400 bg-base-100 dark:bg-base-100 border rounded-sm border-base-100 text-base-800 dark:text-base-800 focus:bg-white focus:border-base-800 dark:focus:border-base-800"></textarea>

                            <PrimaryButton type="submit" className="mt-2 w-fit">
                                Send A Message
                            </PrimaryButton>
                        </form>
                    </div>

                    <span className='block w-full text-center text-xs mt-20'>Copyright &copy; All Rights Reserved</span>
                </footer>
            </main>

            <Alert isOpen={isOpen} close={() => setIsOpen(false)} />
        </>
    )
}
